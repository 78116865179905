//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
export default {
    name: 'TravelCost',
    components:{
        Multiselect
    },

    data: () => ({
        treatmentSelected: null,
        treatmentList: [],
        countrySelected: null,
        countryList: [],
        travelersSelected: null,
        travelersList: [
            {
                id:'1',
                title:'1',
            },
            {
                id:'2',
                title:'2',
            },
            {
                id:'3',
                title:'3',
            },
            {
                id:'4',
                title:'4',
            },
            {
                id:'5',
                title:'5',
            },
        ],
        hotelsSelected: null,
        hotelsList: [
            {
                id:'3',
                title:'⭐⭐⭐',
            },
            {
                id:'4',
                title:'⭐⭐⭐⭐',
            },
            {
                id:'5',
                title:'⭐⭐⭐⭐⭐',
            },
        ],
        costResult:null,
        costFormActive:false,
    }),

    computed: {
        costButtonDisabled() {
            return !(this.treatmentSelected&&this.countrySelected&&this.travelersSelected&&this.hotelsSelected)?true:false
        }
    },

    methods : {
        async calculateCost() {
            if(!this.costButtonDisabled){
                if(!this.costFormActive) {
                    const costResultResponse = await this.$api.fetchCostResult(this.treatmentSelected.id,this.countrySelected.id,this.travelersSelected.id,this.hotelsSelected.id)
                    this.costResult = costResultResponse.content;
                    this.costFormActive = true
                }else{
                    this.costResult = null
                    this.treatmentSelected = null
                    this.countrySelected = null
                    this.travelersSelected = null
                    this.hotelsSelected = null
                    this.costFormActive = false
                }
            }
        },
    },

    async fetch() {
        try {
            const treatmentResponse = await this.$api.fetchHomeTreatment()
            this.treatmentList = treatmentResponse.content;
            const countryResponse = await this.$api.fetchHomecountry()
            this.countryList = countryResponse.content;
        } catch (error) {
            this.$toast.error(error.response.data.message)
        }
    },
}
